import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../components/layout/layout';
import Avatar from '../components/avatar';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Marginnote = makeShortcode("Marginnote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = (props, location) => <Layout seoTitle="About Daniel Chapman" description="A short introduction to who I am and what I'm all about." type="👋🏻" location={location} {...props} />;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "about-me"
    }}>{`About Me`}</h1>
    <Avatar mdxType="Avatar" />
    <p>{`My name is Daniel Chapman and I am a writer, poet, and all around technology enthusiast
raised in the Pacific Northwest and living in Virginia. I
am passionate about making both poetry and technology more accessible to those outside
those fields.`}</p>
    <p>{`Explore this site for more information about me and my eccentric variety of projects`}<Marginnote count={1} mdxType="Marginnote">{`Eccentric feels like a strong word, but one of my projects is learning the 1976 text editor - `}<a parentName="p" {...{
          "href": "/notes/tool.emacs",
          "title": "tool.emacs|Emacs"
        }}>{`[[tool.emacs|Emacs]]`}</a>{``}</Marginnote>{`, including my poetry collection `}{`—`}{` `}<a parentName="p" {...{
        "href": "/poetry/seasons-of-thought"
      }}>{`Seasons of Thought`}</a>{` and my current tech projects.`}</p>
    <p>{`I love learning and as a firm believer in the liberal arts, I don’t think learning should be segmented, so here you’ll see my interests represented — literature, poetry, web development, coding, and whatever else I decide to put on here. Enjoy, I certainly enjoyed making it!`}</p>
    <h2 {...{
      "id": "follow-me-on-social-media"
    }}>{`Follow Me on Social Media`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/ds_chapman"
        }}>{`Twitter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://instagram.com/dschapmanbooks"
        }}>{`Writing Instagram`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://danielchapman.dev"
        }}>{`My Web Portfolio`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://instagram.com/dschapmanphotos"
        }}>{`Photo Instagram`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://facebook.com/dschapmanbooks"
        }}>{`Facebook`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.goodreads.com/author/show/19228078.D_S_Chapman"
        }}>{`Goodreads`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/dschapman"
        }}>{`GitHub`}</a></li>
    </ul>
    <h1 {...{
      "id": "about-this-website"
    }}>{`About This Website`}</h1>
    <p>{`This website currently has three different types of content — `}<a parentName="p" {...{
        "href": "/articles"
      }}>{`articles`}</a>{`, `}<a parentName="p" {...{
        "href": "/poetry"
      }}>{`poems`}</a>{`, and `}<a parentName="p" {...{
        "href": "/notes"
      }}>{`notes`}</a>{` — but the unifying metaphor is one of a digital garden, a place where content grows and develops over time, is interconnected and where content is presented in organic as well as manicured ways.`}<Marginnote count={2} mdxType="Marginnote">{`For more on digital gardens `}<a parentName="p" {...{
          "href": "/notes/web.digital-garden",
          "title": "web.digital-garden|check out my note"
        }}>{`[[web.digital-garden|check out my note]]`}</a>{`.`}</Marginnote></p>
    <p>{`In order to help distinguish the different types of links on this website I have styled links in three different ways. `}<a parentName="p" {...{
        "href": "/"
      }}>{`Internal links`}</a>{` are underlined in blue, while `}<a parentName="p" {...{
        "href": "/notes"
      }}>{`links to my notes`}</a>{` are highlighted, and `}<a parentName="p" {...{
        "href": "https://wikipedia.org"
      }}>{`external links`}</a>{` are distinguished by red underlines.`}</p>
    <p>{`I hope you enjoy exploring this website. If you’re curious about how this website was built make sure to check out `}<a parentName="p" {...{
        "href": "/articles/how-i-coded-this-website"
      }}>{`how I coded this website`}</a>{` or `}<a parentName="p" {...{
        "href": "https://github.com/dschapman/PersonalBlog"
      }}>{`the source code`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      